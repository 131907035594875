<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar
      @click.stop="clickAvatar"
    >
      <v-progress-circular
        v-if="!isNaN(item.porcentajeProgreso)"
        size="40"
        :value="formattedItem.porcentajeProgreso"
        :color="formattedItem.color"
        class="caption"
      >
        {{ formattedItem.porcentajeProgreso }}%
      </v-progress-circular>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <v-chip
          v-if="formattedItem.material_sistema.fdesinstalacion"
          small
          label
          :color="MATERIAL_SISTEMA.colores.desinstalado"
          dark
          class="mr-1"
        >
          DESINSTALADO
        </v-chip>
        <v-chip
          v-if="formattedItem.material_sistema.nelemento"
          small
          label
          class="mr-1"
        >
          {{ formattedItem.material_sistema.nelemento }}
        </v-chip>
        <span
          :class="{ 'text-decoration-line-through': !!formattedItem.material_sistema.fdesinstalacion }"
        >{{ formattedItem.title }}</span>
      </v-list-item-title>
      <v-list-item-subtitle
        class="break-word"
      >
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <v-chip
          v-for="(accion, index) in formattedItem.acciones"
          :key="index"
          small
          label
          text-color="white"
          :color="accion.color"
          class="mr-1"
          @click.stop="clickAccion(accion)"
        >
          {{ accion.desc_acciones }}
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { GRUPO_CHECKLIST, MATERIAL_SISTEMA } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      MATERIAL_SISTEMA,
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.material_sistema.descripcion
      item.subtitle = this.$offline.materialSistema.resumenFichaTecnica(item).join(' - ')
      if (item.resultado === GRUPO_CHECKLIST.resultado.ok) {
        item.color = GRUPO_CHECKLIST.colorResultado.ok
      } else if (item.resultado === GRUPO_CHECKLIST.resultado.conAnomalias) {
        item.color = GRUPO_CHECKLIST.colorResultado.conAnomalias
      } else {
        item.color = GRUPO_CHECKLIST.colorResultado.sinContestar
      }
      return item
    }
  },
  methods: {
    clickAccion (accion) {
      this.$emit('click-accion', { accion, item: this.formattedItem })
    },
    clickAvatar () {
      this.$emit('click-avatar', { item: this.formattedItem })
    },
  }
}
</script>

<style scoped>
.break-word {
  word-break: break-word;
  white-space: normal;
}
</style>
